import { useEffect, useState } from 'react'
import { isEmpty, isNull } from 'lodash'
import * as api from 'api/api'
import Loader from 'components/loaders/Loader'
import { useApp } from 'contexts/AppContext'
import { useQueryParams } from 'hooks/useQueryParams'
import config from 'config'

const whitelistedCountries = [
  'USA',
  'CANADA',
  'CHINA',
  'AUSTRALIA',
  'NEW ZEALAND',
  'HONG KONG',
  'TAIWAN',
  'MALAYSIA',
  'THAILAND',
  'SINGAPORE',
  'GERMANY',
  'BELGIUM',
  'UNITED KINGDOM',
  'NETHERLANDS',
  'KOREA (THE REPUBLIC OF)',
]

export default function GuidAuth({ children }) {
  // State
  const [hasDispatched, setHasDispatched] = useState(false)
  const [hasValidated, setHasValidated] = useState(false)

  // Context
  const {
    state: { rep, customer },
    dispatch,
  } = useApp()
  const { params } = useQueryParams()

  // Check if any guid is set by param so we can clear out session storage of other guids.
  // This also helps us not to validate a guid in session storage if a different param guid is set.
  const idSetByParam = !!params.get('guid')
  const customerGuidSetByParam = !!params.get('customer_guid')
  const id = customerGuidSetByParam ? null : params.get('guid') || window?.sessionStorage?.getItem('guid')
  const customer_guid = idSetByParam ? null : params.get('customer_guid') || window?.sessionStorage?.getItem('customer_guid')
  

  useEffect(() => {    

    if (!isNull(id) || !isNull(customer_guid)) {

      if (id) {

        // Clear out session storage of customer_guid if guid is set by param.
        if(idSetByParam) {
          window?.sessionStorage?.setItem('guid', id)
          window?.sessionStorage?.removeItem('customer_guid')
        }

        const validateGuid = async () => {
          const res = await api.authenticate({ guid: id })
  
          if (res.token) {
            const repInfo = await api.getProfileInfo()
  
            dispatch({
              type: 'updateRep',
              rep: repInfo,
            })
  
            setHasDispatched(true)
          }
  
          setHasValidated(true)
        }
  
        validateGuid()
      }
      if (customer_guid) {

        // Clear out session storage of guid if customer_guid is set by param.
        if(customerGuidSetByParam) {
          window?.sessionStorage?.setItem('customer_guid', customer_guid)
          window?.sessionStorage?.removeItem('guid')
        }

        const validateGuid = async () => {
          const res = await api.authenticateCustomer({ guid: customer_guid })
          if (res.token) {
            let customerInfo = await api.getCustomerAttributes()

            if (Array.isArray(customerInfo) && customerInfo.length === 0) {
              customerInfo = [
                {
                    "Description": "Loyalty Program Level",
                    "Value": "5",
                    "DropdownValueLabel": "VIP",
                },
                {
                    "Description": "New U Rewards Month Counter",
                    "Value": "0",
                    "DropdownValueLabel": null,
                },
                {
                    "Description": "New U Rewards Missed Months",
                    "Value": "0",
                    "DropdownValueLabel": null,
                }
              ]
            }

            dispatch({
              type: 'updateCustomer',
              customer: customerInfo,
            })
  
            setHasDispatched(true)
          }
  
          setHasValidated(true)
        }
  
        validateGuid()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, customer_guid])

  // Development Mode.
  if (isNull(id) && isNull(customer_guid) && (config.APP_ENV === 'development' || config.APP_ENV === 'localhost')) {
    return children
  }

  // Return nothing until guid is validated.
  if (!hasValidated && (isEmpty(rep) || isEmpty(customer))) {
    return <Loader active />
  }

  // Return children if guid has been validated and returned rep information.
  if (hasValidated && hasDispatched) {
    if (!isEmpty(customer)) return children
    if (isNull(rep.RepDID) && !isEmpty(rep)) {
      return <p>RepDID was not found.</p>
    } else if (isNull(customer) && !isNull(customer_guid)) {
      return <p>Customer was not found.</p>
    } else if (!whitelistedCountries.includes(rep.country)) {
      return <p>This page is not available for {rep.country} reps.</p>
    } else {
      return children
    }
  }

  // Return session expired if guid has been validated but errored out.
  if (hasValidated && !hasDispatched) {
    return <p>Your session has expired.</p>
  }

  return null
}
