import { useEffect, useState, useMemo, Fragment } from 'react'
import TermsModal from './TermsModal'
import { useApp } from 'contexts/AppContext'
import { 
  Stack, 
  Typography, 
  Divider, 
  Checkbox, 
  FormControlLabel,
  Link,
} from '@newulife/common'
import { useContentfulContext } from 'hooks/useContentful'
import { isEmpty, find } from 'lodash'
import replaceLabelPlaceholders, { getLabelName } from 'helpers/replaceLabelPlaceholders'

const TermsAndConditions = ({
  form,
  onSetForm,
  onAddToForm,
  fields,
  terms,
  marketingContentfulTerms,
  isKorea
}) => {
  const {
    state: { rep: {
      email: repEmail,
      phone1: repPhone,
    } },
  } = useApp();
  const { title } = useContentfulContext({ entryPoint: 'fields.inputModelArray.2.fields' })
  const contentfulKRTerms = useContentfulContext({ entryPoint: 'fields.inputModelArray.2.fields.listArray.0.fields' })
  const [modalOpen, setModalOpen] = useState({ open: false, modalProps: {} })
  const allChecked = form.terms && Object.values(form.terms).every(term => term?.IsAgreedTo) && !isEmpty(form.terms)

  //For Label Replacement
  const repInfoMap = {
    email: repEmail,
    phone: repPhone,
  }

  const mapTerms = (checkboxItems, replaceLabel = false) => {
    return checkboxItems?.map?.(o => {
      let temp = {}
      let relatedTerms = []
      
      terms?.forEach(o1 => {
        if (o1.AreaId === o?.fields?.byDesignAreaId) {
          temp = o1
          // If we're in Korea and this is the marketing consent term (AreaId -5)
          // collect the related marketing terms (-8 and -9)
          if (isKorea && o1.AreaId === -5) {
            relatedTerms = terms.filter(term => 
              term.AreaId === -8 || term.AreaId === -9
            )
          }
        }
      })
      
      if (isEmpty(temp)) return null
      
      const replaceLabelValue = replaceLabel ? getLabelName(o?.fields?.checkboxLabel) : null
      const newCheckboxLabel = replaceLabelValue ? replaceLabelPlaceholders(o?.fields?.checkboxLabel, repInfoMap[replaceLabelValue]) : o?.fields?.checkboxLabel
      
      const result = [
        {
          ...temp,
          ...o.fields,
          checkboxLabel: newCheckboxLabel,
        },
        ...relatedTerms
      ]
      return result
    })?.filter(o => !!o).flat() || []
  }

  const [
    requiredOptions,
    optionalOptions,
    totalTerms,
  ] = useMemo(() => {
    if (!isKorea) return [[], []]

    const tempRequired = mapTerms(contentfulKRTerms.requiredCheckboxItems)
    const tempOptional = mapTerms(contentfulKRTerms.optionalCheckboxItems)

    return [
      tempRequired,
      tempOptional,
      (tempRequired?.length || 0) + (tempOptional?.length || 0),
    ]
  }, [
    terms,
    isKorea,
    contentfulKRTerms,
  ])

  const marketingOptinOptions = useMemo(() => {
    // Korea doesn't include the marketing opt-in terms for functional reasons, so we'll handle these differently
    if (isKorea)
      return marketingContentfulTerms?.optionalCheckboxItems?.map(
        (o) => o?.fields,
      ); 
    
    const tempMarketingOptin = mapTerms(marketingContentfulTerms?.optionalCheckboxItems, true)
    return tempMarketingOptin
  }, [terms, marketingContentfulTerms, isKorea])

  const handleCheckAll = e => {
    if (allChecked) {
      document.querySelectorAll('.js-check').forEach(el => {
        if (el.querySelector('input').checked) el.querySelector('input').click()
      })
    } else {
      document.querySelectorAll('.js-check').forEach(el => {
        if (!el.querySelector('input').checked) el.querySelector('input').click()
      })
    }
  }

  

  const handleTermsChange = (idx, idx1, field, e) => {
    // If Korea, we set the marketing misc fields based on the user interacting with the optional checkbox field
    const miscFieldsToAdd = field?.AreaId !== -5 ? {} : 
      marketingOptinOptions?.reduce((acc, o) => {
        acc[o.byDesignMiscField] = e.target.checked
        return acc
      },{})
      
    onAddToForm({}, {...miscFieldsToAdd})
    onSetForm((prev) => {
      const terms =
        prev.terms?.map?.((o) => {
          if (o.TermId === field?.TermId) {
            return {
              ...o,
              IsAgreedTo: !o.IsAgreedTo,
              ...(o.MiscField ? { MiscField: o.MiscField } : {}),
            };
          }

          // If the clicked term has AreaId -5 (Korea optional term), also update terms with AreaId -8 and -9
          if (
            field?.AreaId === -5 &&
            (o?.MiscField === 'MarketingOptIn' || o?.MiscField === 'MarketingOptInSMS')
          ) {
            return {
              ...o,
              IsAgreedTo: !o.IsAgreedTo,
              ...(o.MiscField ? { MiscField: o.MiscField } : {}),
            };
          }

          return o;
        }) || [];

      return {
        ...prev,
        terms,
      };
    });
    
    // Update the marketing misc fields
    onAddToForm({}, {...miscFieldsToAdd})
  };

  useEffect(() => {
    const allTerms = isKorea ? [
      ...(requiredOptions || []).map(o => ({ 
        TermId: o?.TermId, 
        IsAgreedTo: o?.IsAgreedTo,
      })),
      ...(optionalOptions || []).map(o => ({ 
        TermId: o?.TermId, 
        IsAgreedTo: o?.IsAgreedTo, 
        MiscField: o?.MiscField,
      }))
    ] : [
      ...(marketingOptinOptions || []).map(o => ({ 
        TermId: o?.TermId, 
        IsAgreedTo: o?.IsAgreedTo, 
        MiscField: o?.MiscField,
      }))
    ]
    
    if (allTerms.length > 0) {
      // Initialize marketing misc fields to false only if we have term with AreaId -5
      const hasMarketingTerm = optionalOptions?.some(term => term.AreaId === -5)
      const miscFieldsToAdd = (isKorea && hasMarketingTerm) ? 
        marketingOptinOptions?.reduce((acc, o) => {
          acc[o.byDesignMiscField] = false
          return acc
        },{}) : {}

      onAddToForm({ terms: allTerms }, miscFieldsToAdd)
    }
  }, [requiredOptions, optionalOptions, marketingOptinOptions, isKorea])

  if (isKorea && totalTerms === 0) return null

  return (
    <Stack gap={3} className="form-max">
      {isKorea && (
        <Typography variant="header2" fontWeight="400" textAlign="center">
          {title}
        </Typography>
      )}
      {totalTerms > 0 && (
        <Stack gap={4}>
          {totalTerms > 1 && (
            <>
              <div className="form-max flex-20 column">
                {/* {sectionList?.map((item) => <p key={item}>{item}</p>)} */}
                <Stack gap={2}>
                  <FormControlLabel
                    sx={{
                      alignItems: "flex-start",
                    }}
                    control={
                      <Checkbox
                        id="select-all"
                        onChange={handleCheckAll}
                        checked={allChecked}
                      />
                    }
                    label={
                      <Stack gap={1} pt={0.3}>
                        <Typography
                          as="label"
                          variant="body3"
                          htmlFor="select-all"
                        >
                          {
                            contentfulKRTerms.selectAllCheckboxItem?.fields
                              ?.name
                          }
                        </Typography>
                      </Stack>
                    }
                  />
                  <Typography variant="body3">
                    {
                      contentfulKRTerms.selectAllCheckboxItem?.fields
                        ?.disclaimerText
                    }
                  </Typography>
                </Stack>
              </div>
              <Divider />
            </>
          )}
          <div className="form-max flex-20 column">
            {[requiredOptions, optionalOptions].map(
              (o, idx) =>
                o?.length > 0 && (
                  <Fragment key={`termsSection${idx}`}>
                    {optionalOptions?.length > 0 && (
                      <Typography variant="body1">
                        {idx === 0
                          ? contentfulKRTerms.requiredSectionText
                          : contentfulKRTerms.optionalSectionText}
                      </Typography>
                    )}

                    {o?.map?.((field, idx1) => {
                      if (isKorea && (field?.AreaId === -8 || field?.AreaId === -9)) {
                        return null;
                      }
                      return (
                      <FormControlLabel
                        key={`terms${idx}${idx1}`}
                        sx={{
                          alignItems: "flex-start",
                        }}
                        control={
                          <Checkbox
                            className="js-check"
                            id={field?.TermId.toString()}
                            name={field?.TermId.toString()}
                            checked={
                              !!find(form.terms, { TermId: field?.TermId })
                                ?.IsAgreedTo
                            }
                            onChange={(e) => handleTermsChange(idx, idx1, field, e)}
                          />
                        }
                        label={
                          <Stack gap={1} pt={0.3}>
                            {field?.checkboxLabel && (
                              <Typography
                                as="label"
                                variant="body3"
                                htmlFor={field?.TermId}
                              >
                                {field?.checkboxLabel}
                              </Typography>
                            )}
                            {field?.linkedFile?.fields?.file?.url ? (
                              <Link
                                href={field?.linkedFile?.fields?.file?.url}
                                target="_blank"
                                underline="hover"
                                sx={{
                                  fontWeight: 500,
                                  display: "block",
                                }}
                              >
                                {field?.linkLabel}
                              </Link>
                            ) : field?.modalText ? (
                              <Link
                                underline="hover"
                                sx={{
                                  fontWeight: 500,
                                  display: "block",
                                }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setModalOpen({
                                    open: true,
                                    modalProps: field,
                                  });
                                }}
                              >
                                {field?.linkLabel}
                              </Link>
                            ) : null}
                          </Stack>
                        }
                      />
                    )})}
                  </Fragment>
                )
            )}
          </div>
        </Stack>
      )}
      {!isKorea && (
        <Typography variant="body1" fontWeight="400" textAlign="center">
          {marketingContentfulTerms?.optionalFieldsDescription}
        </Typography>
      )}
      {(!isKorea && marketingOptinOptions?.length > 0) && (
        <div className="form-max flex-20 column">
          {marketingOptinOptions?.map?.((field, idx1) => {
            const idx = 3;
            return (
              <FormControlLabel
                key={`terms${idx}${idx1}`}
                sx={{
                  alignItems: "flex-start",
                }}
                control={
                  <Checkbox
                    className="js-check"
                    id={field?.TermId?.toString()}
                    name={field?.TermId?.toString()}
                    checked={
                      !!find(form.terms, { TermId: field?.TermId })?.IsAgreedTo
                    }
                    onChange={(e) => handleTermsChange(idx, idx1, field, e)}
                  />
                }
                label={
                  <Stack gap={1} pt={0.3}>
                    {field?.checkboxLabel && (
                      <Typography
                        as="label"
                        variant="body3"
                        htmlFor={field?.TermId}
                      >
                        {field?.checkboxLabel}
                      </Typography>
                    )}
                    {field?.linkedFile?.fields?.file?.url ? (
                      <Link
                        href={field?.linkedFile?.fields?.file?.url}
                        target="_blank"
                        underline="hover"
                        sx={{
                          fontWeight: 500,
                          display: "block",
                        }}
                      >
                        {field?.linkLabel}
                      </Link>
                    ) : field?.modalText ? (
                      <Link
                        underline="hover"
                        sx={{
                          fontWeight: 500,
                          display: "block",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setModalOpen({ open: true, modalProps: field });
                        }}
                      >
                        {field?.linkLabel}
                      </Link>
                    ) : null}
                  </Stack>
                }
              />
            );
          })}
        </div>
      )}
      {modalOpen.open && (
        <TermsModal
          {...modalOpen.modalProps}
          onClose={() => setModalOpen({ open: false, modalProps: {} })}
        />
      )}
    </Stack>
  );
}

export default TermsAndConditions